<template>
  <vx-card title="Pricing Management V2">
    <vs-tabs>
      <vs-tab label="Reg. Price">
        <list-price></list-price>
      </vs-tab>
      <vs-tab label="Price Rule">
        <div class="tab-text">
          <list-price-rule></list-price-rule>
        </div>
      </vs-tab>
      <vs-tab label="|" disabled></vs-tab>
      <!--
      <vs-tab label="Reg. Discount (Total)">
        <div class="tab-text">
          <discount></discount>
        </div>
      </vs-tab>
      -->
      <vs-tab label="Reg. Discount (Item)">
        <div class="tab-text">
          <list-discount-item></list-discount-item>
        </div>
      </vs-tab>
      <vs-tab label="Discount Rule">
        <div class="tab-text">
          <list-discount-rule></list-discount-rule>
        </div>
      </vs-tab>
      <vs-tab label="|" disabled></vs-tab>
      <vs-tab label="Pricing Set">
        <list-price-set></list-price-set>
      </vs-tab>
      <vs-tab label="Discount Set">
        <list-discount-set></list-discount-set>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import ListPrice from "./regular-price/list";
import ListPriceRule from "./price-rule/list";
// import Discount from "./regular-discount/list";
import ListDiscountItem from "./regular-discount-item/list";
import ListDiscountRule from "./discount-rule/list";
import ListDiscountSet from "./discount-set/list";
import ListPriceSet from "./price-set/list";
export default {
  components: {
    ListPrice,
    ListPriceRule,
    // Discount,
    ListDiscountItem,
    ListDiscountRule,
    ListDiscountSet,
    ListPriceSet
  }
};
</script>