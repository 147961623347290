<template>
    <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
            <vx-card>
                <span>
                    <h4 class="mb-5" v-if="this.$route.params.id">Edit Price Set</h4>
                    <h4 class="mb-5" v-else>Create Price Set</h4>
                </span>
                <div class="vx-row mb-6" style="width:100%;">
                    <vs-button
                        class="ml-4 mt-2"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-arrow-left"
                        @click="handleBack()"
                    >Back</vs-button>
                </div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Code</span>
					</div>
					<div class="vx-col sm:w-1/5 w-full">
                        <vs-input class="w-full" name="code" v-model.lazy="data.code" readonly/>
					</div>
					<div class="vx-col sm:w-1/5 w-full">
                        <v-select v-validate="'required'" name="currency code" v-model.lazy="data.currency_code" label="Code" placeholder="Select currency code" :options="optionCurrency" @input="setSelectedCurrencyCode">
                            <template slot="option" slot-scope="option">
                                <div style="align-items: center;"><span>{{option.Code}}</span></div>
                            </template>
                        </v-select>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('currency code')"
                            >{{ errors.first('currency code') }}
                        </span>
					</div>
                    <div class="vx-col sm:w-1/5 w-full flex">
                        Active&nbsp;
                        <vs-switch name="is active" v-model="data.is_active"/>
                    </div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Name</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input v-validate="'required'" class="w-full" name="name" v-model.lazy="data.name"/>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('name')"
                            >{{ errors.first('name') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Valid From</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <datepicker :disabled="read" v-validate="'required'" name="valid from" :inline="false" v-model.lazy="data.valid_from" placeholder="Select Date"></datepicker>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('valid from')"
                            >{{ errors.first('valid from') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Valid To</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <datepicker :disabled="read" v-validate="'required'" name="valid to" :inline="false" v-model.lazy="data.valid_to" placeholder="Select Date"></datepicker>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('valid to')"
                            >{{ errors.first('valid to') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Price Rule</span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-5/5 w-full mb-2" v-for="(item, indextr) in items" :key="indextr">
                        <div class="vx-row mb-2">
                            <div class="vx-col w-1/5" style="display: flex">
                                <vs-input label="Code" class="w-full" :value="item.code" readonly/>
                            </div>
                            <div class="vx-col w-2/5" style="display: flex">
                                <vs-input label="Name" class="w-full" :value="item.name" readonly/>
                            </div>
                            <div class="vx-col w-2/5" style="display: flex">
                                <vs-input @keypress="isNumber($event)" label="Priority" :value="item.priority" @change="item.priority = $event.target.value; itemSort();" class="w-full" type="number" min="0" name="Priority"/>
                            </div>
                        </div>
                    </div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col">
						<vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
					</div>
				</div>
                
            </vx-card>
        </div>
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect
    },
    data() {
        return {
            read: false,
            items: [],
            selected_items: [],
            optionCurrency: [],
            data: {
                code: "",
                currency_code: "",
                name: "",
                valid_from: "",
                valid_to: "",
                is_active: false,
            },
        };
    },
    methods: {
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        itemSort() {
            this.items.sort(function(a, b) {
                return a.priority - b.priority;
            });
        },
        handleBack() {
            this.$router.push("/master/pricing-management-v2");
        },
        setSelectedCurrencyCode(value) {
            this.data.currency_code = value.Code;
        },
        getOptionCurrency(){
            this.$http
            .get("/api/v1/master/currency").then(resp => {
                if (resp.status == "success") {
                    this.optionCurrency = resp.data.records;
                }
            });
        },
        getCode() {
            this.$vs.loading();
            this.$http
            .get("/api/v1/master/engine-price/code")
            .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.data.code = resp.data
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
                
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        getActivePriceRule() {
            var _this = this
            this.$vs.loading();
            this.$http.get("/api/v1/master/price-rule", {
                params: {
                    currency_code: _this.data.currency_code,
                    is_active: true
                }
            }).then(resp => {
                _this.items = []
                if (resp.status == "success") {
                    for (var i = 0; i < resp.data.records.length; i++) {
                        let element = resp.data.records[i]
                        _this.items.push({
                            id: element.id,
                            code: element.code,
                            name: element.name,
                            priority:  _this.findLinePriorityByID(element.id),
                        })
                    }
                    this.$vs.loading.close();
                }
            });
        },
        findLinePriorityByID(id) {
            for (var i = 0; i < this.selected_items.length; i++) {
                let item = this.selected_items[i]
                if(item.id == id) {
                    return item.priority
                }
            }
            return 0
        },
        handleSubmit() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.$vs.loading();
                    var _this = this
                    this.items.forEach(function(element, index){
                        _this.items[index].id = element.id
                        _this.items[index].code = element.code
                        _this.items[index].name = element.name
                        _this.items[index].priority = parseInt(element.priority)
                    })
                    if (this.$route.params.id) {
                        this.putData();
                    } else {
                        this.postData();
                    }
                }
            });
        },
        paramData() {
            return {
                id: parseInt(this.$route.params.id),
                code: this.data.code,
                name: this.data.name,
                valid_from: this.data.valid_from,
                valid_to: this.data.valid_to,
                currency_code: this.data.currency_code,
                is_active: this.data.is_active,
                line: this.items
            };
        },
        postData() {
            this.$http
                .post("/api/v1/master/engine-price", this.paramData())
                .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.handleClose();
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: "New Price Set Created",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                    this.$router.push("/master/pricing-management-v2");
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        putData() {
            this.$http
            .put("/api/v1/master/engine-price/" + this.$route.params.id, this.paramData())
            .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.handleClose();
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: "List Price Updated",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("close");
        },
        handleAddItem(indextr, tr){
            this.items.splice(indextr+1, 0, {
                id: 0,
                code: tr.code,
                name: tr.name,
                priority: 0
            })
        },
        getData() {
            this.$vs.loading();
            this.$http
            .get("/api/v1/master/engine-price/" + this.$route.params.id)
            .then(resp => {
                var _this = this
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.data.code = resp.data.code;
                    this.data.name = resp.data.name;
                    this.data.valid_from = resp.data.valid_from;
                    this.data.valid_to = resp.data.valid_to;
                    this.data.currency_code = resp.data.currency_code;
                    this.data.is_active = resp.data.is_active;
                    
                    _this.selected_items = [];
                    resp.data.line.forEach(function(element){
                        _this.selected_items.push({
                            id: element.PriceRuleID,
                            code: element.PriceRule.Code,
                            name: element.PriceRule.Name,
                            priority: element.Priority,
                        })
                    })
                    this.itemSort()

                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
    },
    mounted() {
        if(!(this.$route.params.id)){
            this.getCode();
        } else {
            this.getData();
        }
        this.getOptionCurrency();
        this.getActivePriceRule()
    },
    computed: {},
    watch: {
        "data.name": function(val) {
            if(val == ""){
                this.errors.add({
                    field: "name",
                    msg: "The name is required"
                });
            } else {
                this.errors.clear()
            }
        },
        "data.code": function(val) {
            if(val == false){
                this.errors.add({
                    field: "code",
                    msg: "The code is required"
                });
            } else {
                this.errors.clear()
            }
        },
        "data.currency_code": function(val) {
            if(val == false){
                this.errors.add({
                    field: "currency_code",
                    msg: "The currency code is required"
                });
            } else {
                this.errors.clear()
                this.getActivePriceRule()
            }
        },
        "data.valid_from": function(val) {
            if(val == false){
                this.errors.add({
                    field: "valid_from",
                    msg: "The valid from is required"
                });
            } else {
                this.errors.clear()
            }
        },
        "data.valid_to": function(val) {
            if(val == false){
                this.errors.add({
                    field: "valid_to",
                    msg: "The valid to is required"
                });
            } else {
                this.errors.clear()
            }
        },
    }
};
</script>